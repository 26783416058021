<template>
<div>
    <h1 class="px-2 mt-4 text-xl font-sans font-semi-bold bg-purple-300 rounded-t-lg">Testimoni</h1>
    <div class="mt-4">
    </div>
</div>
</template>
<script>
  export default {
    el: '#post',
    name : 'FAQ',
    data(){
    return{
                web:'',
    }
  },
    components:{
  },
    mounted() {
  },
    computed: {
  },
    methods:{

    }
}
</script>
